.page-template-home {

  @media only screen {
    margin-top: 94px;
  }

  .hero {

    .hero-slider {
      overflow: hidden;
      position: relative;

      .swiper-slide {
        position: relative;

        &__image {
          width: 100%;
          height: 100%;
          max-height: 100%;

          @include media($screen-sm, max) {
            min-height: 400px;
            object-fit: cover;
          }
        }

        &__content {
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translate(-50%, 0);
          text-align: center;
          max-width: 1000px;
          width: 100%;
        }

        &__header {
          color: white;
          font-family: $font-secondary;
          font-size: 66px;
          margin: 0 0 20px 0;
          line-height: 70px;
          font-weight: 600;
          text-shadow: 1px 1px 2px #000000;

          @include media($screen-sm, max) {
            font-size: 52px;
            line-height: 56px;
          }
        }

        &__subheader {
          color: white;
          font-size: 24px;
          margin: 0;
          font-weight: 400;
          font-style: italic;
          line-height: 30px;
          text-shadow: 1px 1px 2px #000000;
        }
      }

      .swiper-button-prev, .swiper-button-next {
        color: white;
      }
    }
  }

  .testimonials {
    background-color: $brand-azure-blue;
    border-top-left-radius: 80% 10%;
    border-top-right-radius: 80% 10%;
    border-bottom-left-radius: 80% 10%;
    border-bottom-right-radius: 80% 10%;
    margin: 3rem 0;

    .testimonials-slider {
      @include wrapper();
      overflow: hidden;
      position: relative;

      .swiper-slide {
        position: relative;

          &-prev, &-next, &-duplicate {
            visibility: hidden;
          }

          &-active {
            visibility: visible !important;
          }
      }
    }

    .testimonial {
      display: grid;
      grid-template-columns: 1fr 200px;
      color: white;

      @include media($screen-sm, max) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }

      &__content {
        padding: 1rem;
      }

      &__info {
        padding: 1rem;
      }
    }
  }

  .products {
    margin-bottom: 60px;

    &__wrapper {
      @include wrapper;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, 220px);
      justify-content: space-between;
      grid-gap: 10px;

      @include media(1140px, max) {
        justify-content: center;
      }
    }

    .products-grid-item {
      text-align: center;
      padding: 20px;
      border: 1px solid $brand-slate;

      &__image {
        margin: 0 auto;
      }

      &__title {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }

  .brands {
    background-color: $brand-azure-blue;
    border-top-left-radius: 80% 10%;
    border-top-right-radius: 80% 10%;
    border-bottom-left-radius: 80% 10%;
    border-bottom-right-radius: 80% 10%;
    border-top: 40px solid $brand-azure-blue;
    border-bottom: 40px solid $brand-azure-blue;
    margin-bottom: 60px;

    &__wrapper {
      @include wrapper(900px);
    }

    .brands-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      &__item {

        &_title {
          color: white;
          font-size: 28px;
          position: relative;
          margin-right: 40px;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 34px;
            background-color: white;
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .content {
    margin-bottom: 60px;

    &__grid {
      @include wrapper;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 60px;
      position: relative;

      @include media(800px, max) {
        grid-gap: 1rem;
      }
    }

    &__box {

      &:first-of-type {

        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 100%;
          background-color: lighten($brand-slate, 60);
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          @include media(800px, max) {
            display: none;
          }
        }
      }

      h3 {

        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    .content-slider {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      .swiper-slide {

        &__image {
          width: 100%;
          height: 235px;
          object-fit: cover;
        }
      }

      .swiper-pagination {
        display: flex;
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 10;

        &__bullet {
          display: block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid $brand-night-blue;
          cursor: pointer;

          &_active {
            background-color: $brand-night-blue;
          }

          &:not(:last-child) {
            margin-right: 6px;
          }
        }
      }
    }

    &__cta {
      text-align: center;
    }

    &__tagline {
      color: lighten($brand-slate, 20);
      font-family: $font-primary;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}