.learning-center {

  h4 {
    color: $brand-azure-blue !important;
  }

  > .container {
    width: 100%;
  }

  .reg {
    font-size:12px;
    vertical-align:top;
  }

  &__image-wrapper {
    border: 12px solid #dddddd;
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    grid-gap: 2rem;
    justify-content: space-between;

    @include media(1150px, max) {
      justify-content: center;
    }
  }
}