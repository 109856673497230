@import "./../../scss/settings/variables";
@import "./../../scss/settings/mixins";

.product-finder {

  &__wrapper {
    @include wrapper();
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-gap: 2rem;
    margin: 80px auto;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }

  &__sidebar {
    border-right: 1px solid $brand-azure-blue;
    padding: 0 2rem 0 0;

    @include media($screen-sm, max) {
      border-right: none;
      border-bottom: 1px solid $brand-azure-blue;
      padding: 0 0 2rem 0;
    }
  }

  .pf-filters {

    &__title {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 1rem 0;
    }

    &__clear-btn-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .product-finder-filter {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    border: 1px solid $brand-azure-blue;
    padding: 10px;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &__select {
      border-radius: 0;
      border: 2px solid $brand-azure-blue;
      padding: 3px 10px;
    }
  }

  &__results {

  }

  .product-finder-results {

    &__title {
      text-align: center;
      margin: 0;
    }

    &__subtitle {
      text-align: center;
      margin: 0;
    }
  }

  .product-finder-group {
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 265px));
    grid-gap: 1rem;
    grid-row-gap: 2rem;
    justify-content: center;
  }

  .pfg-item {
    cursor: pointer;
    border: 1px solid $brand-azure-blue;

    &__title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    &__image {
      display: block;
      width: 100%;
      height: 350px;
      object-fit: cover;
      margin-bottom: -16px;
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;
    }

    &__button {
      margin-bottom: -18px;
      width: 100px;
      height: 34px;
    }
  }

  .product-finder-products {
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }

  .pfp-item {
    color: black;
    cursor: pointer;
    border: 1px solid $brand-azure-blue;

    &__title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    &__image {
      display: block;
      width: 100%;
      height: 250px;
      object-fit: contain;
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;
    }

    &__button {
      margin-bottom: -18px;
      width: 100px;
      height: 34px;
    }
  }
}