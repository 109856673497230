.footer {
  color: white;
  position: relative;
  background-image: url("./../images/common/footer-bg.jpg");
  background-size: cover;
  padding: 40px 0 25px 0;

  &::before {
    content: "";
    background-color: $brand-azure-blue;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  &__wrapper {
    @include wrapper;
    position: relative;
    z-index: 20;
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    flex-grow: 1;
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    h4 {
      font-family: $font-primary;
      color: white;
      margin-top: 0;
    }

    .hq-image {
      width: 100%;
      height: 100%;
      max-width: 351px;
      max-height: 169px;
      object-fit: cover;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: white;
    }
  }

  .footer-navigation {
    list-style: none;
    margin: 0;
    padding: 0;

    &__link {
      color: white;

      @include media($screen-sm, max) {
        padding: 14px 15px;
        display: block;
      }
    }
  }
}