.header {
  top: 0;
  z-index: 30;
  width: 100%;

  @media only screen {
    position: fixed;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: $brand-azure-blue;
    background: linear-gradient(0deg, rgba(7,42,150,1) 0%, rgba(7,42,150,1) 44%, rgba(2,57,226,1) 100%);
  }

  &__wrapper {
    @include wrapper;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__logo {
    display: block;
    width: 203px;
    height: 88px;
    margin: 0;
    padding: 3px 0;
    box-sizing: content-box;

    @media only print {
      display: none;
    }

    &_print {
      display: none;

      @media only print {
        display: block;
        width: 156px;
      }
    }
  }
  
  .header-bar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $brand-night-blue;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 8px 4px 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @include media($wrapper-max-width, max) {
      right: 2rem;
    }

    a {
      color: white;
    }

    &__phone {
      position: relative;
      margin-right: 15px;

      &::after {
        display: block;
        content: "";
        width: 2px;
        height: 14px;
        background-color: white;
        position: absolute;
        right: -10px;
        top: 5px;
      }
    }

    &__lang {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 18px;
        background-repeat: no-repeat;
        background-color: white;
        margin-right: 4px;
        background-size: cover;
        background-position: center;
      }

      &_en {

        &::before {
          background-image: url("./../images/distributors/flags/us.svg");
        }
      }

      &_es {

        &::before {
          background-image: url("./../images/distributors/flags/es.svg");
        }
      }

      &_ja {

        &::before {
          background-image: url("./../images/distributors/flags/jp.svg");
        }
      }

      &_ko {

        &::before {
          background-image: url("./../images/distributors/flags/kr.svg");
        }
      }

      &_zh-CN {

        &::before {
          background-image: url("./../images/distributors/flags/cn.svg");
        }
      }

      &_zh-TW {

        &::before {
          background-image: url("./../images/distributors/flags/tw.svg");
        }
      }

      &_de {

        &::before {
          background-image: url("./../images/distributors/flags/de.svg");
        }
      }

      &_it {

        &::before {
          background-image: url("./../images/distributors/flags/it.svg");
        }
      }

      &_fr {

        &::before {
          background-image: url("./../images/distributors/flags/fr.svg");
        }
      }

      &_pt {

        &::before {
          background-image: url("./../images/distributors/flags/pt.svg");
        }
      }
    }
  }

  .language-selector {
    color: white;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border: none;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    padding-left: 24px;
    margin-left: -24px;
    outline: none;

    &__option {
      background-color: $brand-night-blue;
    }
  }

  &__gtr {
    position: absolute;
    top: 0;
    right: 2rem;
    z-index: 50;
    padding: 1rem;
    background-color: white;
    display: none;
  }

  .navigation {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    @include media(1000px, max) {
      display: none;
    }

    &__item {
      position: relative;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &_has-dropdown {

        &:hover {

          .navigation__dropdown_level-1 {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &_technical {

        .navigation__dropdown {

          &_level-2 {
            min-width: 180px;
            left: -181px;
          }

          &_level-3 {
            min-width: 225px;
            left: -226px;
          }
        }
      }
    }

    &__link {
      color: white;
      font-family: $font-secondary;
      font-size: 20px;
      position: relative;
      z-index: 15;
    }

    &__dropdown {
      list-style: none;
      padding: 3px 0;
      position: absolute;
      top: 59px;
      left: -15px;
      margin-top: -70px;
      z-index: 10;
      background-color: lighten($brand-azure-blue, 40);
      border-top: 70px solid $brand-azure-blue;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease opacity, 0.3s ease visibility;

      &_level-1 {

        .navigation__dropdown-item_has-dropdown {

          &:hover {

            .navigation__dropdown_level-2 {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      &_level-2 {
        margin-top: 0;
        border-top: none;
        left: calc(100% + 1px);
        top: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease opacity, 0.3s ease visibility;

        .navigation__dropdown-item_has-dropdown {

          &:hover {

            .navigation__dropdown_level-3 {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      &_level-3 {
        margin-top: 0;
        border-top: none;
        left: calc(100% + 1px);
        top: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease opacity, 0.3s ease visibility;
      }
    }

    &__dropdown-item {

      &:not(:last-child) {
        border-bottom: 1px solid lighten($brand-azure-blue, 20);
      }

      &_has-dropdown {
        position: relative;
      }
    }

    &__dropdown-link {
      display: inline-block;
      color: black;
      white-space: nowrap;
      padding: 0.4rem 1rem;
      width: 100%;

      &:hover {
        background-color: lighten($brand-azure-blue, 30);
      }
    }
  }

  .slicknav_menu {
    display: none;
    position: relative;
    width: 100%;
    background-color: transparent;
    padding: 0;

    @include media(1000px, max) {
      display: block
    }

    .slicknav_btn {
      top: -50px;
      right: 2rem;
      margin: 0;
      position: absolute;
    }

    .slicknav_nav {
      position: relative;
      width: 100%;
      background-color: white;
      border-top: 1px solid #F8F5F1;
      border-bottom: 1px solid #F8F5F1;
      padding: 2rem;
      z-index: 50;

      .slicknav_row {
        display: flex;
        margin: 0;
        padding-left: 0;
        font-size: 18px;

        &:hover {
          color: $brand-azure-blue;
        }

        //a {
        //  font-size: 18px !important;
        //}
      }

      a {
        font-family: $font-secondary;
        color: $brand-azure-blue;
        padding: 0;
        margin-left: 0;
        outline: none;
        line-height: 28px;
        font-size: 18px;

        &:hover {
          background-color: white;
        }
      }

      .slicknav_arrow {
        font-size: 1.5em;
        line-height: 25px;
      }

      .slicknav_parent {

        ul {

          li {

            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}