.page-template-product-detail {

  .main {
    @include wrapper();
    margin: 3rem auto;

    .intro {

      &__grid {
        @include wrapper;
        display: grid;
        grid-template-columns: 6fr 5fr;
        grid-gap: 60px;
        position: relative;

        @include media($screen-md, max) {
          grid-gap: 1rem;
          grid-template-columns: 1fr;
          padding-right: 0;
          padding-left: 0;
        }
      }

      &__box {
        position: relative;

        &:first-of-type {

          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 100%;
            background-color: lighten($brand-slate, 60);
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(2rem);

            @include media($screen-sm, max) {
              display: none;
            }
          }
        }

        h3 {
          margin-top: 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 22px;
              height: 21px;
              background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAYAAABCIB6VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAABRklEQVR42uTUPUvDQBwG8CdSglFsm2Trq93cxdXFD5AK0qFfwlWjBjFocHLWzbVTIjo5uLh2cBL6kqa69UVsErUtMXEoDtpT0rQFwWe943fPcX+O8jzPwxQygynl78ClagNn57cwre5k4eube6hXdxBl7Vd8ZDi/sYJUgoNutLB9oP6IU0Gm4rnzBlHWYDy0kUnzUKQswguz4z9eNMJAkQQspnjU6m1ic2Ljfv/d1wEd8xWScon64xMyaR5HewIiYYYMb24VUNabgUYsGWdxepIHAIS+LzIMDZoO+cZc14PjDG5IUdR4j/cZ+6WH3cMLlCoNJGIsjvezYKNz5MZ+Y9kDtFwdRgPDtt3DjqyhUmsS0UCwaXUhyhp0o4VknIUiCUNooDkuqMUvKMfOE/eN3HhtdQmO4yK3vgyO0HQiU/HPP3q/+RgAwQKKY2+nxUcAAAAASUVORK5CYII=');
              background-size: 22px 21px;
              background-repeat: no-repeat;
              margin-right: 5px;
            }

            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
