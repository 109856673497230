.page-template-applications {

  .main {
    @include wrapper();
    margin: 3rem auto;
  }

  .intro {

    &__title {
      color: $brand-azure-blue;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 2rem;
    }

    &__cell {

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .applications {
    margin-bottom: 3rem;

    &__title {
      font-size: 36px;
      line-height: 40px;
      font-weight: 400;
      text-align: center;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 1rem;
    }

    .application-card {

      &__image {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
  }

  .marked-list {

    &__item {
      margin-bottom: 10px;
    }
  }

  .learning-center {
    margin-top: 3rem;
  }
}