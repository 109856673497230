.page-template-right-sidebar {

  .content-wrapper {
    @include wrapper();
    display: grid;
    grid-template-areas: "main sidebar";
    grid-template-columns: 1fr 300px;
    grid-gap: 3rem;
    margin: 3rem auto;

    @include media($screen-sm, max) {
      grid-template-areas: "main"
                           "sidebar";
      grid-template-columns: 1fr;
    }

    .main {
      grid-area: main;
    }

    .sidebar {
      grid-area: sidebar;
    }
  }
}