// Responsive Breakpoints
$screen-xs: 320px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1366px;
$screen-xl: 1920px;

// Content Box
$wrapper-max-width: 1140px;

// Colors
$brand-black: #1a1a1a;
$brand-slate: #282828;
$brand-azure-blue: #072a96;
//$brand-azure-blue: #495594;
$brand-night-blue: #1d275c;
$brand-yellow: #f8bb57;
$brand-gray: #dddddd;