@import "./../../scss/settings/variables";
@import "./../../scss/settings/mixins";

.cad-downloader {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid black;

  &__wrapper {
    @include wrapper(800px);

    @include media($screen-sm, max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-areas: "preview controls"
                         "preview form"
                         "preview messages";
    grid-template-columns: 280px 1fr;
    grid-template-rows: auto auto 1fr;

    @include media($screen-sm, max) {
      grid-template-areas: "controls"
                           "form"
                           "messages"
                           "preview";
      grid-template-columns: 1fr;
    }
  }

  &__preview {
    grid-area: preview;
  }

  &__controls {
    grid-area: controls;
  }

  &__form {
    grid-area: form;
  }

  &__messages {
    grid-area: messages;
  }

  .messages {
    padding: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .cd-form {

    &__form {
      max-height: 0;
      overflow: hidden;
      transition: 0.3s ease max-height;
      border-left: 1px solid $brand-azure-blue;
      border-right: 1px solid $brand-azure-blue;
      border-bottom: 1px solid $brand-azure-blue;

      &_active {
        max-height: 100%;
      }
    }

    &__wrapper {
      padding: 0.5rem;
    }
  }

  .cd-preview {

    &__image {
        display: block;
        width: 100%;
        height: 350px;
        object-fit: contain;
    }
  }

  .cd-controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "product-selector cad-format-selector cad-download-button-wrapper";
    border: 1px solid $brand-azure-blue;

    @include media(600px, max) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "product-selector cad-format-selector"
                           "cad-download-button-wrapper cad-download-button-wrapper";
    }

    .product-selector, .cad-format-selector, .cad-download-button-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;

      label {
        font-weight: 600;
        flex-grow: 1;
      }

      select {
        width: 100%;
        border-radius: 0;
        border: 2px solid $brand-azure-blue;
        padding: 3px 10px;
      }
    }

    .product-selector {
      grid-area: product-selector;
    }

    .cad-format-selector {
      grid-area: cad-format-selector;
      border-left: 1px solid $brand-azure-blue;
      border-right: 1px solid $brand-azure-blue;
    }

    .cad-download-button-wrapper {
      grid-area: cad-download-button-wrapper;
      justify-self: center;
      align-self: center;

      @include media(600px, max) {
        width: 100%;
        border-top: 1px solid $brand-azure-blue;
      }
    }

    .cad-download-button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}