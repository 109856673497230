.page-template-full-width {

  .main {
    @include wrapper();
    margin: 3rem auto;

    .content {
      margin-bottom: 60px;

      &__grid {
        max-width: $wrapper-max-width;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 60px;
        position: relative;

        @include media($screen-sm, max) {
          padding-left: 0;
          padding-right: 0;
          grid-template-columns: 1fr;
          grid-column-gap: 1rem;
          grid-row-gap: 0;
        }
      }

      &__box {

        &:first-of-type {

          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 100%;
            background-color: lighten($brand-slate, 60);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @include media($screen-sm, max) {
              display: none;
            }
          }
        }

        h3 {
          margin-top: 0;
        }

        img {
          width: 100%;
          object-fit: contain;
          max-width: 500px;

          @include media($screen-sm, max) {
            max-width: 100%;
          }
        }
      }
    }
  }
}