//@font-face {
//  font-family: 'Open Sans';
//  src: url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.eot');
//  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'),
//  url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
//  url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.woff2') format('woff2'),
//  url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.woff') format('woff'),
//  url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.ttf') format('truetype'),
//  url('./../fonts/OpenSans/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('./../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Semibold.eot');
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('./../fonts/OpenSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-Semibold.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('./../fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('./../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-Light.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans.eot');
  src: local('Open Sans'), local('OpenSans'),
  url('./../fonts/OpenSans/OpenSans.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('./../fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSans-SemiboldItalic.eot');
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
  url('./../fonts/OpenSans/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSans-SemiboldItalic.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSans-SemiboldItalic.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSans-SemiboldItalic.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans/OpenSansLight-Italic.eot');
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url('./../fonts/OpenSans/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/OpenSans/OpenSansLight-Italic.woff2') format('woff2'),
  url('./../fonts/OpenSans/OpenSansLight-Italic.woff') format('woff'),
  url('./../fonts/OpenSans/OpenSansLight-Italic.ttf') format('truetype'),
  url('./../fonts/OpenSans/OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

//@font-face {
//  font-family: 'Open Sans';
//  src: url('./../fonts/OpenSans/OpenSans-Extrabold.eot');
//  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
//  url('./../fonts/OpenSans/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
//  url('./../fonts/OpenSans/OpenSans-Extrabold.woff2') format('woff2'),
//  url('./../fonts/OpenSans/OpenSans-Extrabold.woff') format('woff'),
//  url('./../fonts/OpenSans/OpenSans-Extrabold.ttf') format('truetype'),
//  url('./../fonts/OpenSans/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-Bold.eot');
  src: local('Oswald Bold'), local('Oswald-Bold'),
  url('./../fonts/Oswald/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-Bold.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-Bold.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-Bold.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-Bold.svg#Oswald-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-ExtraLight.eot');
  src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
  url('./../fonts/Oswald/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-ExtraLight.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-ExtraLight.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-ExtraLight.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-ExtraLight.svg#Oswald-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-Medium.eot');
  src: local('Oswald Medium'), local('Oswald-Medium'),
  url('./../fonts/Oswald/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-Medium.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-Medium.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-Medium.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-Medium.svg#Oswald-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-Light.eot');
  src: local('Oswald Light'), local('Oswald-Light'),
  url('./../fonts/Oswald/Oswald-Light.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-Light.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-Light.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-Light.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-Light.svg#Oswald-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-Regular.eot');
  src: local('Oswald Regular'), local('Oswald-Regular'),
  url('./../fonts/Oswald/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-Regular.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-Regular.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-Regular.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-Regular.svg#Oswald-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('./../fonts/Oswald/Oswald-SemiBold.eot');
  src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
  url('./../fonts/Oswald/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Oswald/Oswald-SemiBold.woff2') format('woff2'),
  url('./../fonts/Oswald/Oswald-SemiBold.woff') format('woff'),
  url('./../fonts/Oswald/Oswald-SemiBold.ttf') format('truetype'),
  url('./../fonts/Oswald/Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Oswald', sans-serif;

h1, h2, h3 {
  color: $brand-azure-blue;
  font-family: $font-secondary;
}

h1 {
  //font-size: 66px;
  font-size: 63px;
  line-height: 60px;
}

h2 {
  font-size: 52px;
  line-height: 60px;
  font-weight: 400;
}

h3 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
}